
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";
import IAgrupadorTipoContaImporte from "@/lib/interfaces/agrupador-tipo-conta-importe";
import IContaImporte from "@/lib/interfaces/conta-importe";
import ITipoImporte from "@/lib/interfaces/tipo-importe";

export default Vue.extend({
  name: "AgrupadoresTipoContaImporteList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    agrupadorTipoContaImporte: {
      type: Object as PropType<IAgrupadorTipoContaImporte>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      agrupadoresTipoContaImporte: new Array<IAgrupadorTipoContaImporte>(),

      contasImporte: new Array<IContaImporte>(),
      tiposImporte: new Array<ITipoImporte>(),

      headers: [
        { text: "Conta importe", value: "cContaImporte", align: "start" },
        { text: "Tipo importe", value: "cTipoImporte", align: "start" },
        {
          text: "Status",
          value: "cDesativado",
          sortable: false,
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getAgrupadoresTipoContaImporte().then();
    },

    options: {
      deep: true,
      handler() {
        this.getAgrupadoresTipoContaImporte().then();
      },
    },
  },

  computed: {
    cAgrupadoresTipoContaImporte(): Array<Record<string, unknown>> {
      return this.agrupadoresTipoContaImporte.map((agrupador) => ({
        ...agrupador,
        cContaImporte:
          this.contasImporte.find(
            (contaImporte) => contaImporte.id === agrupador.id_conta_importe
          )?.descricao ?? agrupador.id_conta_importe,
        cTipoImporte:
          this.tiposImporte.find(
            (tipoImporte) => tipoImporte.id === agrupador.id_tipo_importe
          )?.descricao ?? agrupador.id_tipo_importe,
        cDesativado: agrupador.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  mounted() {
    this.getContasImporte().then();
    this.getTiposImporte().then();
  },

  methods: {
    async openAgrupadorTipoContaImporte(id: string) {
      const agrupadorTipoContaImporte = this.agrupadoresTipoContaImporte.find(
        (agrupador) => agrupador.id === id
      );
      this.$emit("update:agrupador-tipo-conta-importe", {
        ...agrupadorTipoContaImporte,
      });
    },

    async getAgrupadoresTipoContaImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("agrupador_tipo_conta_importe.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.agrupadoresTipoContaImporte =
          rsp.getTable("agrupador_tipo_conta_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getContasImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("conta_importe.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.contasImporte =
          rsp.getTable("conta_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getTiposImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_importe.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.tiposImporte = rsp.getTable("tipo_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
