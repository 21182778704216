
import Vue from "vue";
import AgrupadoresTipoContaImporteList from "@/components/subviews/agrupadores-tipo-conta-importe/agrupadores-tipo-conta-importe-list.vue";
import AgrupadoresTipoContaImporteMaintenance from "@/components/subviews/agrupadores-tipo-conta-importe/agrupadores-tipo-conta-importe-maintenance.vue";
import IAgrupadorTipoContaImporte from "@/lib/interfaces/agrupador-tipo-conta-importe";

export default Vue.extend({
  name: "AgrupadoresTipoContaImporte",

  components: {
    AgrupadoresTipoContaImporteList,
    AgrupadoresTipoContaImporteMaintenance,
  },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      agrupadorTipoContaImporte: {} as IAgrupadorTipoContaImporte,
    };
  },
});
