
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import { required, maxLength } from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ACard from "@/components/elements/a-card.vue";
import IAgrupadorTipoContaImporte from "@/lib/interfaces/agrupador-tipo-conta-importe";
import ASelect from "@/components/elements/a-select.vue";
import IContaImporte from "@/lib/interfaces/conta-importe";
import ITipoImporte from "@/lib/interfaces/tipo-importe";

export default Vue.extend({
  name: "TiposImporteMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ACard,
    ASelect,
  },

  props: {
    agrupadorTipoContaImporte: {
      type: Object as PropType<IAgrupadorTipoContaImporte>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      contasImporte: new Array<IContaImporte>(),
      tiposImporte: new Array<ITipoImporte>(),

      dataAgrupadorTipoContaImporte: {
        id: "",
        id_conta_importe: "",
        id_tipo_importe: "",
        desativado: "0",
      } as IAgrupadorTipoContaImporte,
    };
  },

  watch: {
    agrupadorTipoContaImporte: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataAgrupadorTipoContaImporte = {
          ...this.agrupadorTipoContaImporte,
        };
      },
    },
  },

  computed: {
    cTextSave(): string {
      return this.dataAgrupadorTipoContaImporte.id ? "Alterar" : "Salvar";
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },
  },

  mounted() {
    this.getContasImporte().then();
    this.getTiposImporte().then();
  },

  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("agrupador_tipo_conta_importe.write");

        const tblAgrupadorTipoContaImporte = new Table(
          "agrupador_tipo_conta_importe"
        );

        const obj = {
          ...this.dataAgrupadorTipoContaImporte,
          id: this.dataAgrupadorTipoContaImporte.id || "default",
        };
        tblAgrupadorTipoContaImporte.setColsFromObject(obj);
        tblAgrupadorTipoContaImporte.addRowsFromObject(obj);

        rq.addTable(tblAgrupadorTipoContaImporte);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe um tipo importe com esse nome";

          return;
        } else {
          this.ret_msg = `Tipo importe  ${
            this.dataAgrupadorTipoContaImporte.id ? "atualizado" : "cadastrado"
          } com sucesso`;
        }

        this.dataAgrupadorTipoContaImporte.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataAgrupadorTipoContaImporte = {
        id: "",
        id_conta_importe: "",
        id_tipo_importe: "",
        desativado: "0",
      };

      this.form.resetValidation();
    },

    async getContasImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("conta_importe.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.contasImporte =
          rsp.getTable("conta_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getTiposImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_importe.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.tiposImporte = rsp.getTable("tipo_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
